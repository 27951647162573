import React, { Component } from "react"
import { graphql, navigate } from "gatsby"
import "@babel/polyfill";

import Layout from "../layouts"
import Head from "../components/head"

import '../css/calculator-print.css';

class PrintCalculator extends Component {

  constructor(props) {
    super(props);

    this.state = props.location.state;

    this.handlePrint = this.handlePrint.bind(this);

    const page = this.props.data.wpPage;
    const { seo } = page
  }

  currencyFormat(num) {
    if(num === '0.00' || num == '')
      return '0.00';

    return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }

  handlePrint() {
    window.print();
  }
  
  render() {

    const page = this.props.data.wpPage;
    const { seo } = page

    if(this.state === null) {

      if(typeof window !== 'undefined')
        navigate(page.wpURI);

      return(<p></p>);
    }
      

    const { state } = this;

    return (
      <Layout>
        <Head title={`Print - ${seo.title}`} />
        <div className="container baseContainer print-container">
          <div className="grid smallGrid">
            <div className="col-md-12 baseContainerLeft maincopy">
              <div className="button-container">
                <button className="printButton" onClick={() => navigate( page.wpURI, { state: state } )}>Return</button>
                <button className="printButton" onClick={this.handlePrint} style={{float: 'right'}}>Print</button>
              </div>

              {state.costFreight ? (
          
              <div className="calcPrint">
                <div className="item" style={{marginTop: 0}}>
                  <h3>Shipment Value:</h3>
                  <span>${this.currencyFormat(state.shipmentValue.value)}</span>
                </div>

                <div className="item">
                  <h3>Cost of Freight:</h3>
                  <span>${this.currencyFormat(state.costFreight.value)}</span>
                </div>

                <div className="item">
                  <h3>Extra Expense: (%)</h3>
                  <span>${this.currencyFormat(state.extraExpense.value)}</span>
                </div>

                <div className="item">
                  <h3>Insured Value:</h3>
                  <span>${this.currencyFormat(state.insuredValue.value)}</span>
                </div>

                <hr />

                <div className="item">
                  <h3>Cargo Insurance Rate:</h3>
                  <span>${this.currencyFormat(state.cargoRate.value)}</span>
                </div>
                <span className="defaultValue">(Per $100 of Value)</span>

                <div className="item">
                  <h3>Total Premium</h3>
                  <span>${this.currencyFormat(state.totalPremium.value)}</span>
                </div>

              </div>
              ) : (
                <div className="calcPrint ata-calc">
                <div className="item" style={{marginTop: 0}}>
                  <h3>Shipment Value:</h3>
                  <p><span>${this.currencyFormat(state.shipmentValue.value)}</span></p>
                </div>
                <div className="item" style={{marginTop: 0}}>
                  <h3>Countries Visited:</h3>
                  <p><span>{state.countries.value}</span></p>
                </div>
                <div className="item" style={{marginTop: 0}}>
                  <h3>Including Brazil?</h3>
                  <p><span>{state.brazil.value === 'Yes' ? 'Yes' : 'No'}</span></p>
                </div>
                <div className="item" style={{marginTop: 0}}>
                  <h3>Basic Fee:</h3>
                  <p><span>${this.currencyFormat(state.basicFee.value)}</span></p>
                </div>
                <div className="item" style={{marginTop: 0}}>
                  <h4>Include ATA Carnet Bond for ${this.currencyFormat(state.carnetBond.value)}?</h4>
                  <p><span>{state.carnetBond.included ? 'Yes' : 'No'}</span></p>
                </div>
                <div className="item" style={{marginTop: 0}}>
                  <h4>Include Lost Document Coverage for ${this.currencyFormat(state.lostDoc.value)}?</h4>
                  <p><span>{state.lostDoc.included ? 'Yes' : 'No'}</span></p>
                </div>
                <div className="item" style={{marginTop: 0}}>
                  <h4>Include ATA Carnet Cargo Insurance for ${this.currencyFormat(state.cargoInsurance.value)}?</h4>
                  <p><span>{state.cargoInsurance.included ? 'Yes' : 'No'}</span></p>
                </div>
                <div className="item" style={{marginTop: 0}}>
                  <h4>Shipping:</h4>
                  <p><span>${state.shipping}</span></p>
                </div>
                <div className="item total">
                  <h2>Total Estimate:</h2>
                  <p><span>${this.currencyFormat(state.totalEstimate.value)}</span></p>
                  </div>
                </div>
              )}
            

            </div>
          </div>
        </div>
      </Layout>
    )
  }
}
export default PrintCalculator


export const query = graphql`
  query($id: String!) {
    wpPage(id: {eq: $id}) {
      title
      uri
      wpURI
      id
      databaseId
      content
      seo {
        ...seoParts
      }
    }
  }
`